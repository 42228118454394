.Mobile {
  margin-top: -8px;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Desktop {
  margin-top: -8px;
  width: 375px;
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Desktop .Botonera,
.Desktop > div .Card,
.Desktop .ColDuo,
.Desktop ol,
.Desktop .campoEmail {
  width: 360px !important;
}

.MobileNavbar {
  min-height: 64px;
}

.Desktop .ColDuo {
  padding: 0;
  margin: 0 8px;
}

.Mobile .SeguroListaImgs img {
  width: 100vw;
  margin: 0 0 -6px 0;
}

.Desktop .SeguroListaImgs img {
  width: 100%;
  margin: 0 0 -4px 0;
}

.MID1 {
  margin: 0 8px;
}

.Botonera {
  background-color: white;
  width: 100%;
}

.Desktop .Botonera {
  background-color: transparent;
}

.btn {
  background-color: #ccc;
  margin: 12px 8px;
  width: calc(100% - 16px);
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: bold;
  color: grey;
  padding: 16px;
}

.btn-acttive {
  background-color: #00c166;
  border: 1px solid #00c166;
  color: white !important;
}

.StickyBottomBox {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

/* ----------------------------------------- */

.Contratar h4,
.FormCotizar h4,
.petList h4 {
  padding: 32px 0 12px 0;
  text-align: center;
  font-weight: lighter;
}

.petList ol {
  width: 100vw;
  padding: 8px;
}

.DrawerBox .petList ol {
  width: 375px !important;
  padding: 8px;
}

.petList ol li article {
  cursor: pointer;
  background-color: white;
  margin-bottom: 12px;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 0 3px 1px #ddd;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.petList ol li article img {
  width: 44px;
  height: 44px;
  background-color: #ddd;
  border-radius: 50%;
  margin-right: 8px;
}

.petList ol li article h6 {
  font-size: 1.1rem;
  font-weight: normal;
  margin-right: 8px;
}

.sinc,
.conc {
  font-weight: bold;
  font-size: 0.8rem;
}

.sinc {
  color: tomato;
}
.conc {
  color: #0098db;
}

.petList ol li article svg {
  font-size: 24px;
  color: #0098db;
}

/* ----------------------------------------- */

.FormCotizar {
  width: 100vw;
}

.DrawerBox .FormCotizar {
  width: 375px;
}

.ColDuo {
  padding: 0 8px;
  display: grid;
  width: 100vw;
  grid-template-columns: 49% 49%;
  column-gap: 2%;
}

.DrawerBox .ColDuo {
  padding: 0 8px;
  display: grid;
  width: 375px;
  grid-template-columns: 49% 49%;
  column-gap: 2%;
}

.FormCotizar .iconBox,
.FormCotizar .iconBoxAct {
  width: 100%;
  height: 120px;

  background-color: white;
  margin-bottom: 12px;
  border-radius: 8px;
  border: 2px solid white;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
}

.FormCotizar .iconBoxAct {
  border: 2px solid #0098db;
}

.FormCotizar .iconBox svg {
  font-size: 64px;
  color: #808080;
}

.FormCotizar .iconBoxAct svg {
  font-size: 64px;
  color: #0098db;
}

.FormCotizar .iconBox .aux {
  font-size: 75px;
}

.FormCotizar input,
.FakeCheckbox {
  padding: 12px 8px;
  background-color: white;
  margin-bottom: 12px;
  border: 2px solid white;
  border-radius: 8px;
  width: 100%;
  position: relative;
}

.FakeCheckbox {
  padding-left: 36px;
}

.FakeCheckbox::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 8px;

  width: 16px;
  height: 16px;
  border: 2px solid #808080;
  border-radius: 50%;
}

.FakeCheckboxActive::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 8px;

  width: 16px;
  height: 16px;
  border: 2px solid #0098db;
  border-radius: 50%;
}

.FakeCheckboxActive::after {
  content: "";
  position: absolute;
  top: 14px;
  left: 12px;

  width: 8px;
  height: 8px;
  background-color: #0098db;
  border: 2px solid #0098db;
  border-radius: 50%;
}

.DataList {
  background-color: #c4efff !important;
  border: 2px solid #c4efff !important;
  padding: 12px 0 !important;
}

.DataList::placeholder {
  color: #0098db;
  text-align: center;
}

.FormCotizar .ZI {
  z-index: 1;
}

.campoEmail {
  padding-top: 12px;
}
.campoEmail label {
  font-size: 0.9rem;
}

.campoEmail input {
  margin-top: 8px;
}

.campoTel {
  z-index: 0;
  position: relative;
}
.campoTel label {
  position: absolute;
  z-index: 1;
  top: 0;
  height: 42px;
  width: 60px;

  background-color: #0098db;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px 0 0 8px;
}

.campoTel input {
  padding: 12px 8px 12px 68px !important;
}

.Contratar .Card,
.Pagar .Card {
  width: calc(100vw - 16px);
}

.DrawerBox .Contratar .Card,
.DrawerBox .Pagar .Card {
  width: calc(375px - 16px);
}

.Contratar .CardSelected {
  box-shadow: 0 0 0 4px #0098db;
}

.Contratar h3 {
  font-size: 1rem;
  text-align: center;
}

.Contratar h3 span {
  text-decoration: line-through;
  color: #808080;
}

.Contratar p {
  text-align: center;
  padding: 8px;
}

.Contratar .ContratarBody .RowCenter {
  justify-content: flex-start;
  padding: 8px 0 0 0;
}

.Contratar .ContratarBody svg {
  color: #00c166;
  margin-right: 8px;
}

.Contratar .RowCenter span {
  font-size: 0.8rem;
}

.Contratar a {
  display: block;
  width: 100%;
  text-align: center;
}

.Pagar .Card p {
  text-align: center;
  font-size: 0.9rem;
}

.AnimacionSeguro {
  width: 100vw;
  height: 100vh;

  max-width: 375px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.declined {
  background-color: tomato;
  box-shadow: 0 0 0 100vw tomato;
}

.Aproved {
  background-color: #0098db;
  box-shadow: 0 0 0 100vw #0098db;
}

.DrawerBox .AnimacionSeguro {
  width: 375px;
  height: 375px;
}

.AnimacionSeguro .imageLogo {
  max-width: 50%;
}

.AnimacionSeguro h4 {
  color: white;
  font-weight: normal;
  width: calc(100% - 16px);
  font-size: 1.3rem;
  text-align: center;
  margin-bottom: 4px;
}

.AnimacionSeguro p {
  color: white;
  font-weight: normal;
  width: calc(100% - 16px);
  font-size: 1rem;
  text-align: center;
  margin-bottom: 12px;
}

.AnimacionSeguro button {
  margin: 4px 8px;
  padding: 12px;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  width: calc(100% - 16px);
  max-width: 365px;
  font-size: 1.2rem;
}

.AnimacionSeguro a {
  color: white;
  text-align: center;
  margin-top: 12px;
}

.ListaTarjetas {
  padding: 4px;
}

.ListaTarjetas li {
  padding: 8px;
  border: 2px solid #fafafa;
  background-color: #fafafa;
  border-radius: 8px;
  margin-top: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Desktop .ListaTarjetas li {
  width: calc(100% - 16px);
}

.ListaTarjetas li img {
  width: 54px;
  min-height: 32px;
  border-radius: 4px;
  margin-top: -4px;
  margin-right: 4px;
}

.ListaTarjetas li input {
  background-color: white;
  border: none;
  border-radius: 4px;
  max-width: 64px;
  padding: 12px 0 !important;
  text-align: center;
}

.ListaTarjetas .active {
  background-color: rgba(31, 168, 231, 0.1);
  border: 2px solid #0098db;
  color: #0098db;
}

.ListaTarjetas .active span {
  color: #0098db;
}

.Pagar .FormPagar button {
  margin-top: 8px;
  border-radius: 8px;
  background-color: rgba(31, 168, 231, 0.1);
  color: #0098db;
  border: none;
  padding: 12px;
  width: 100%;
}

.FormPagar .ColDuo {
  margin: 0;
  padding: 0;
  column-gap: 0;
  justify-content: space-between !important;
  width: 100% !important;
}

.FormPagar fieldset {
  border: none;
  margin: 8px 0;
}

.FormPagar input {
  width: 99%;
  padding: 8px;
  border: none;
  border-bottom: 2px solid #ccc;
}

.FormPagar fieldset span {
  color: red;
  margin-left: 4px;
}

.FormPagar a {
  padding: 16px 0 !important;
  display: block;
  text-align: center;
  font-size: 14px;
}

.CheckBox {
  background-color: #eeeeee;
  font-size: 0.75rem;
  display: grid;
  grid-template-columns: 32px auto;
  justify-content: flex-start !important;
  align-items: center;

  border-radius: 4px;
  margin-bottom: -4px;
}

.CheckBox label {
  padding: 8px;
}

.ShieldBox {
  position: relative;
  margin: 16px 0;
}

.Shield {
  color: white;
  font-size: 128px;
}

.Heart {
  color: red;
  font-size: 64px;

  position: absolute;
  bottom: 0;
  right: 0;
}

.Aproved span {
  color: white;
  font-size: 16px;
}

.Continuar {
  position: absolute;
  bottom: 8px;
}

.appBox {
  margin: 32px 8px 8px 8px;
}

.appBox h4 {
  font-size: 1rem;
  text-align: center;
  margin: 0 0 8px 8px;
  padding: 0;
}
.appBox img {
  width: calc(50% - 16px);
  margin: 8px;
}

fieldset {
  padding: 0;
  border: none;
}

fieldset input {
  margin-top: 4px;
  height: 45px;
}

fieldset label {
  padding: 0 0 8px 0;
  font-size: 14px;
}

.Onboarding {
  box-shadow: 0 0 0 100vh #f0f0f0, 0 250vh 0 100vh #f0f0f0;
  background-color: #f0f0f0;
}

.Onboarding li {
  position: relative;
}

.CardComentarios {
  margin: 0 8px 8px 8px !important;
  max-width: calc(100vw - 16px);
}

.CardComentarios img {
  z-index: 0;
  max-width: 130px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.CardComentarios .acordeon {
  border: 1px solid #ddd;
  margin: 8px 0 0 0;
  padding: 8px;
  border-radius: 8px;
}

.CardComentarios .acordeon .acordeonHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #0c92d5;
  height: 32px;
}

.acordeonHead svg {
  transition: all 0.5s;
}

.CardComentarios .acordeon .acordeonBody {
  height: 0;
  overflow: hidden;
  transition: all 0.5s;
}

.CardComentarios .acordeon:hover .acordeonBody {
  height: auto;
  margin-top: 8px;
}

.CardComentarios .acordeon:hover .acordeonHead svg {
  transform: rotate(180deg);
}

.btn-acttive small {
  font-weight: normal;
  font-size: 12px;
}

.NavbarSeguro {
  position: relative !important;
}

.NavbarSeguro .OliverLogo {
  margin-top: 12px;
}

/* === === === === === === === === === === === === === */

.Presseable {
  height: 64px;
  width: 50%;
  background-color: rgba(255, 0, 0, 0);
  position: absolute;
  top: -12px;

  z-index: 1;
}

.Presseable:nth-child(2) {
  right: 0;
}

/* === === === === === === === === === === === === === */

.OnboardingDesktop {
  position: relative;
  margin-top: 16px;
  background-color: white;
  box-shadow: 0 0 0 1000px white;
}

.OnboardingDesktop img {
  width: 1280px;
}

.listPopUps {
  background-color: rgba(0, 0, 0, 0);
  min-width: 790px;
  margin-left: 50px;
  position: absolute;
  top: 575px;

  display: flex;
}

.listPopUps li {
  height: 80px;
  width: 25%;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(206, 117, 224, 0);
  margin-top: 12px;
}

.OnboardingDesktop .Botonera {
  position: absolute;
  top: 655px;
  left: -60%;

  transform: scale(0.7);
}

.modal-over {
  position: fixed;
  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-body {
  background-color: white;
  width: 500px;
  padding: 16px;
  border-radius: 16px;

  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.modal-body p {
  margin-top: 12px;
}

.modal-body button {
  margin-top: 8px;
  background-color: #0098db;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  color: white;
}

.StickyBottomBoxBtn {
  cursor: pointer;
}
